<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h2>Overzicht leeftijdsgroepen</h2>
					<!-- <infoDropdown>
                        Deze grafiek is niet gekoppeld aan de datumpikker hierboven.
                    </infoDropdown> -->
				</div>
				<div class="pg_header_description">
					<p>Een onderverdeling van de leeftijd van de leden</p>
				</div>
			</header>
			<div class="pg_gender_charts">
				<label v-if="userInfo.admin" for>
					Vergelijk met:
					<select v-model="branch" class="v_transparent">
						<option value="none">Geen</option>
						<option
							v-for="(item, i) in filteredBranches"
							:key="`branch-option-${i}`"
							:value="item.id"
						>
							{{ item.vestigingNaam }}
						</option>
					</select>
				</label>
				<div class="pg_chart">
					<highcharts v-if="!loading" :options="chartOptions"></highcharts>
				</div>
			</div>
			<hr />
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "Leden",
	components: {
		Loader,
	},
	data() {
		return {
			branch: "none",
			loading: false,
			chartOptions: {
				chart: {
					marginTop: 60,
					type: "column",
				},
				legend: {
					enabled: false,
				},
				xAxis: {
					categories: [],
					title: {
						text: "leeftijd",
					},
					startOnTick: false,
					showFirstLabel: true,
					crosshair: {
						width: 0,
						color: "transparent",
					},
				},
				yAxis: {
					title: {
						text: "Percentage",
					},
					labels: {
						formatter() {
							return `${this.value}%`;
						},
					},
				},
				tooltip: {
					shared: false,
					formatter() {
						return `<b>${this.y.toFixed(2)}%</b> leden zijn tussen de ${this.x.replace(
							"-",
							" en ",
						)} jaar oud`;
					},
				},
				series: [
					{
						data: [],
						color: "rgba(38, 35, 97, 1)",
					},
				],
			},
		};
	},
	computed: {
		...mapState("auth", ["userInfo"]),
		...mapState("statistieken", ["query"]),
		...mapState("branches", {
			branches: "items",
			selectedBranch: "selected",
		}),
		filteredBranches() {
			return this.branches.filter((branch) => branch.id !== this.selectedBranch.id);
		},
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
		branch() {
			this.load();
		},
	},
	methods: {
		...mapActions("leden", ["getAgeMembers"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;

				let vestiging;
				if (this.branch && this.branch !== "none") {
					vestiging = this.branches.find((branch) => branch.id === this.branch);
				}

				const result = (await this.getAgeMembers(vestiging)).sort((a, b) => {
					if (a.omschrijving < b.omschrijving) {
						return -1;
					} else if (a.omschrijving > b.omschrijving) {
						return 1;
					}

					return 0;
				});

				if (vestiging) {
					this.chartOptions.legend.enabled = true;

					this.$set(this.chartOptions.series, 1, {
						data: [],
						color: "#d4145a",
					});
					this.$set(this.chartOptions.series[1], "name", vestiging.vestigingNaam);
					this.$set(this.chartOptions.series[0], "name", this.selectedBranch.vestigingNaam);

					result.forEach((item, index) => {
						this.$set(this.chartOptions.series[1].data, index, item.percentage);
					});
				} else {
					this.chartOptions.legend.enabled = false;

					this.$delete(this.chartOptions.series, 1);

					result.forEach((item, index) => {
						this.$set(this.chartOptions.xAxis.categories, index, item.omschrijving);
						this.$set(this.chartOptions.series[0].data, index, item.percentage);
					});
				}

				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 42px);
	left: calc(100% - 42px);
}

.pg_gender_charts {
	display: flex;
	flex-direction: column;
	label {
		align-self: flex-end;
	}
}

.pg_chart {
	height: 400px;
	width: 100%;
}
</style>
