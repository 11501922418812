<template>
	<div>
		<!-- <period></period> -->

		<section class="pg_content">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h1 class="s_elem-has-icon-left a_fill_default">
								Leden
								<icon :icon-id="'icon_leden'"></icon>
							</h1>
						</div>
						<div class="pg_header_description">
							<p>
								De belangrijkste indicatoren rondom de leden van
								{{ selected.vestigingNaam }}
							</p>
						</div>
					</header>

					<div class="pg_kpis a_margin-top-40 a_margin-bottom-20" data-s-amount="4">
						<statistics-badge-rect
							title="Bezoeken"
							soort="aantal-bezoeken"
							:selected="selectedBadge == 'aantal-bezoeken'"
							@selected="selectedBadge = 'aantal-bezoeken'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Bezoekers"
							soort="aantal-unieke-bezoeken"
							:selected="selectedBadge == 'aantal-unieke-bezoeken'"
							@selected="selectedBadge = 'aantal-unieke-bezoeken'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Leden geholpen"
							soort="aantal-geholpen-leden"
							:selected="selectedBadge == 'aantal-geholpen-leden'"
							@selected="selectedBadge = 'aantal-geholpen-leden'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Actie's"
							soort="aantal-acties"
							:selected="selectedBadge == 'aantal-acties'"
							@selected="selectedBadge = 'aantal-acties'"
						></statistics-badge-rect>
					</div>

					<visits-chart :type="selectedBadge"></visits-chart>

					<hr />
				</div>
			</div>
		</section>

		<section class="pg_content a_margin-top-40">
			<average-attendance></average-attendance>
		</section>

		<section class="pg_content a_margin-top-40">
			<inactive-members></inactive-members>
		</section>

		<section class="pg_content a_margin-top-40">
			<average-membership-time></average-membership-time>
		</section>

		<section class="pg_content a_margin-top-40">
			<gender-comparison />
		</section>
		<section class="pg_content a_margin-top-40">
			<age-comparison />
		</section>
	</div>
</template>

<script>
import { mapState } from "vuex";
import StatisticsBadgeRect from "@/components/elements/StatisticsBadgeRect.vue";
import AgeComparison from "./partials/AgeComparison.vue";
import AverageAttendance from "./partials/AverageAttendance.vue";
import AverageMembershipTime from "./partials/AverageMembershipTime.vue";
import GenderComparison from "./partials/GenderComparison.vue";
import InactiveMembers from "./partials/InactiveMembers.vue";
import VisitsChart from "./partials/VisitsChart.vue";

export default {
	name: "Leden",
	components: {
		StatisticsBadgeRect,
		VisitsChart,
		AverageAttendance,
		AverageMembershipTime,
		InactiveMembers,
		GenderComparison,
		AgeComparison,
	},
	data() {
		return {
			selectedBadge: "aantal-bezoeken",
		};
	},
	computed: {
		...mapState("branches", ["selected"]),
	},
};
</script>
