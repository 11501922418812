<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h2>Opkomstpercentage</h2>
					<infoDropdown> Deze grafiek is niet gekoppeld aan de datum kiezer bovenin. </infoDropdown>
				</div>
				<div class="pg_header_description">
					<p>De opkomst in percentage deze week, tot nu toe.</p>
				</div>
			</header>
			<highcharts :options="chartOptions"></highcharts>
			<hr />
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InfoDropdown from "@/components/elements/InfoDropdown.vue";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "Leden",
	components: {
		InfoDropdown,
		Loader,
	},
	data() {
		return {
			loading: false,
			chartOptions: {
				chart: {
					type: "bar",
					marginTop: 0,
					height: 240,
					marginLeft: 0,
					spacingLeft: 0,
					marginRight: 0,
					spacingRight: 0,
				},
				legend: {
					enabled: false,
				},
				xAxis: {
					title: {
						text: false,
					},
					categories: ["Vorige week", "Deze week", `Gemiddelde in ${new Date().getFullYear()}`],
					crosshair: {
						width: 0,
						color: "transparent",
					},
					showFirstLabel: true,
					labels: {
						x: 10,
						align: "left",
						reserveSpace: false,
						style: {
							color: "#262261",
							whiteSpace: "nowrap",
						},
						useHTML: true,
					},
				},
				yAxis: {
					title: {
						text: false,
					},
					labels: {
						enabled: false,
					},
					gridLineColor: "transparent",
					min: 0,
					max: 100,
				},
				plotOptions: {
					series: {
						dataLabels: {
							color: "#262261",
							enabled: true,
							x: 10,
							style: {
								fontSize: "13px",
								fontWight: "normal",
							},
							formatter() {
								return this.point.t;
							},
						},
						pointPadding: 0,
						groupPadding: 0.05,
					},
				},
				tooltip: {
					enabled: false,
				},
				series: [
					{
						data: [
							{
								y: 48,
								t: "48%",
								borderColor: "#262261",
								color: "rgba(38, 35, 97, 0.1)",
							},
							{
								y: 46,
								t: "46%",
								borderColor: "#d4145a",
								color: "rgba(212, 20, 90, 0.1)",
							},
							{
								y: 51,
								t: "51%",
								borderColor: "#dadae3",
								color: "rgba(38, 35, 97, 0.05)",
							},
						],
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("leden", ["getAverageAttendance"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;
				const result = await this.getAverageAttendance();
				this.chartOptions.series[0].data[0].y = result.dezeWeek.waarde;
				this.chartOptions.series[0].data[1].y = result.vorigeWeek.waarde;
				this.chartOptions.series[0].data[2].y = result.gemiddeldeJaar.waarde;
				this.chartOptions.series[0].data[0].t = `${result.dezeWeek.waarde.toFixed(0)}%`;
				this.chartOptions.series[0].data[1].t = `${result.vorigeWeek.waarde.toFixed(0)}%`;
				this.chartOptions.series[0].data[2].t = `${result.gemiddeldeJaar.waarde.toFixed(0)}%`;
				this.chartOptions.series[0].data = [
					this.chartOptions.series[0].data[0],
					this.chartOptions.series[0].data[1],
					this.chartOptions.series[0].data[2],
				];
				this.chartOptions.series[0].data.forEach((item, index) => {
					if (item.y < 30) {
						item.dataLabels = {
							color: "#262261",
							enabled: true,
							x: [100, 120, 180][index],
						};
					}
				});
				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 42px);
	left: calc(100% - 42px);
}
</style>
