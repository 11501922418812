<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h2>Verdeling man / vrouw</h2>
					<!-- <infoDropdown>
                        Deze grafiek is niet gekoppeld aan de datumpikker hierboven.
                    </infoDropdown> -->
				</div>
				<div class="pg_header_description">
					<p>Verhouding mannelijke en vrouwelijke leden in de club</p>
				</div>
			</header>
			<div class="pg_gender_charts">
				<label v-if="userInfo.admin" for>
					Vergelijk met:
					<select v-model="branch" class="v_transparent">
						<option value="none">Geen</option>
						<option
							v-for="(item, i) in filteredBranches"
							:key="`branch-option-${i}`"
							:value="item.id"
						>
							{{ item.vestigingNaam }}
						</option>
					</select>
				</label>
				<div class="a_flex">
					<highcharts
						class="pg_chart"
						:class="{ 'v_full-width': !userInfo.admin }"
						:options="chartOptions"
					></highcharts>
					<highcharts v-if="chartOptions2" class="pg_chart" :options="chartOptions2"></highcharts>
				</div>
			</div>
			<hr />
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "Leden",
	components: {
		Loader,
	},
	data() {
		return {
			loading: false,
			branch: "none",
			chartOptions2: null,
			chartOptions: {
				chart: {
					type: "pie",
				},
				title: {
					text: "",
					y: 340,
					align: "center",
				},
				legend: {
					enabled: false,
				},
				accessibility: {
					point: {
						valueSuffix: "%",
					},
				},
				tooltip: {
					headerFormat: "",
					pointFormat: "<b>{point.percentage:.2f}%</b> van de leden zijn {point.name}",
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: "pointer",
						dataLabels: {
							enabled: true,
							format: "{point.name}: <b>{point.percentage:.2f}%</b>",
							style: {
								fontSize: ".9rem",
								fontFamily: '"Radikal", Arial, sans-serif',
								fontWeight: 400,
							},
						},
					},
				},
				series: [
					{
						name: "gender",
						data: [
							{
								y: null,
								name: null,
							},
							{
								y: null,
								name: null,
							},
							{
								y: null,
								name: null,
							},
						],
					},
				],
			},
		};
	},
	computed: {
		...mapState("auth", ["userInfo"]),
		...mapState("statistieken", ["query"]),
		...mapState("branches", {
			branches: "items",
			selectedBranch: "selected",
		}),
		filteredBranches() {
			return this.branches.filter((branch) => branch.id !== this.selectedBranch.id);
		},
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
		branch() {
			this.load();
		},
	},
	methods: {
		...mapActions("leden", ["getGenderMembers"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;

				let chart = this.chartOptions;
				let vestiging;
				if (this.branch && this.branch !== "none") {
					chart.title.text = this.selectedBranch.vestigingNaam;

					this.chartOptions2 = JSON.parse(JSON.stringify(this.chartOptions));
					chart = this.chartOptions2;
					vestiging = this.branches.find((branch) => branch.id === this.branch);
					chart.title.text = vestiging.vestigingNaam;
				} else {
					this.chartOptions2 = null;
					this.chartOptions.title.text = "";
				}

				const result = await this.getGenderMembers(vestiging);

				result
					.filter((res) => res.omschrijving == "man" || res.omschrijving === "vrouw")
					.forEach((item, index) => {
						chart.series[0].data[index].name = item.omschrijving;
						chart.series[0].data[index].y = parseFloat(item.percentage.toFixed(2));
					});

				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 42px);
	left: calc(100% - 42px);
}

.pg_gender_charts {
	display: flex;
	flex-direction: column;
	label {
		align-self: flex-end;
	}
}
.pg_chart {
	width: 50%;
	&.v_full-width {
		width: 100%;
	}
}
</style>
