<template>
	<div>
		<highcharts :options="chartOptions"></highcharts>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	components: {
		Loader,
	},
	props: {
		type: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			loading: false,
			chartOptions: {
				chart: {
					marginTop: 60,
					type: "area",
				},
				xAxis: {
					title: {
						text: "tijd",
					},
					type: "datetime",
					labels: {
						align: "center",
						x: 40,
					},
					startOnTick: true,
					showFirstLabel: true,
					dateTimeLabelFormats: {
						day: "%A",
					},
					tickInterval: 24 * 3600 * 1000,
				},
				yAxis: {
					title: {
						text: "aantal",
					},
				},
				plotOptions: {
					series: {
						pointStart: Date.UTC(2017, 10, 6, 7),
						pointInterval: 24 * 3600 * 1000,
					},
				},
				tooltip: {
					xDateFormat: "%A",
				},
				series: [
					{
						name: "deze periode",
						data: [366, 388, 402],
						fillOpacity: 0.1,
					},
					{
						name: "vorige periode",
						data: [320, 300, 412, 419, 200, 356, 265],
						fillOpacity: 0,
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
	},
	watch: {
		type() {
			this.load();
		},
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("statistieken", ["getGraphData", "queryStartDate"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;
				await this.getGraphData({
					...this.query,
					soort: this.type,
					// periode: 'week',
					// vergelijkMet: 'vorige-periode',
					chartOptions: this.chartOptions,
				});
				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 36px - 6px);
	left: 100%;
}
</style>
