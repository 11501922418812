import { render, staticRenderFns } from "./GenderComparison.vue?vue&type=template&id=2866f27e&scoped=true"
import script from "./GenderComparison.vue?vue&type=script&lang=js"
export * from "./GenderComparison.vue?vue&type=script&lang=js"
import style0 from "./GenderComparison.vue?vue&type=style&index=0&id=2866f27e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2866f27e",
  null
  
)

export default component.exports