<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h2>Hoe lang al lid?</h2>
					<infoDropdown>
						Deze grafiek is niet gekoppeld aan de datumpikker hierboven.
					</infoDropdown>
				</div>
				<div class="pg_header_description">
					<p>
						Goede service zorgt ervoor dat mensen lang met plezier bij ons sporten. In onderstaande
						grafiek zie je het percentage leden per aantal jaar dat zij al lid zijn bij ons.
					</p>
				</div>
			</header>
			<highcharts :options="chartOptions"></highcharts>
			<hr />
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import InfoDropdown from "@/components/elements/InfoDropdown.vue";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "Leden",
	components: {
		InfoDropdown,
		Loader,
	},
	data() {
		return {
			loading: false,
			chartOptions: {
				chart: {
					marginTop: 60,
					type: "column",
				},
				legend: {
					enabled: false,
				},
				xAxis: {
					title: {
						text: "aantal jaar",
					},
					startOnTick: false,
					showFirstLabel: true,
					crosshair: {
						width: 0,
						color: "transparent",
					},
				},
				yAxis: {
					title: {
						text: "aantal leden in %",
					},
				},
				tooltip: {
					formatter() {
						let text = "";
						if (this.x === 0) {
							text = `<b>${this.y}%</b> van de leden zijn nog geen jaar lid`;
						} else {
							text = `<b>${this.y}%</b> van de leden zijn nu <b>${this.x}</b> jaar lid`;
						}

						return text;
					},
				},
				series: [
					{
						data: [],
						borderColor: "#262261",
						color: "rgba(38, 35, 97, 0.1)",
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("leden", ["getMembershipTime"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;
				const result = await this.getMembershipTime();
				this.chartOptions.series[0].data =
					result.length > 0 ? result.map((item) => item.percentage) : _.fill(Array(11), 0);
				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 42px);
	left: calc(100% - 42px);
}
</style>
