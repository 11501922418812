<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h2>Aantal weken geleden</h2>
					<infoDropdown>
						Deze grafiek is niet gekoppeld aan de datumpikker hierboven.
					</infoDropdown>
				</div>
				<div class="pg_header_description">
					<p>
						Het aantal leden afgezet tegen de laatste keer dat ze in de sportschool waren. Je leest
						van links naar rechts, bijv. 1000 leden zijn al 2 weken niet meer actief geweest.
					</p>
				</div>
			</header>
			<highcharts :options="chartOptions"></highcharts>
			<hr />
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import InfoDropdown from "@/components/elements/InfoDropdown.vue";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "Leden",
	components: {
		InfoDropdown,
		Loader,
	},
	data() {
		return {
			loading: false,
			chartOptions: {
				chart: {
					marginTop: 60,
					type: "column",
				},
				legend: {
					enabled: false,
				},
				xAxis: {
					title: {
						text: "aantal weken",
					},
					startOnTick: false,
					showFirstLabel: true,
					crosshair: {
						width: 0,
						color: "transparent",
					},
				},
				yAxis: {
					title: {
						text: "aantal leden",
					},
				},
				tooltip: {
					formatter() {
						let text = "";
						if (this.x === 1) {
							text = `<b>${this.y}</b> leden zijn <b>${this.x}</b> week niet actief zijn geweest`;
						} else {
							text = `<b>${this.y}</b> leden zijn <b>${this.x}</b> weken niet actief zijn geweest`;
						}
						if (this.x === 0) {
							text = `<b>${this.y}</b> leden de <b>deze week</b> niet actief zijn geweest`;
						}

						return text;
					},
				},
				series: [
					{
						name: "deze week",
						data: [2500, 3000, 1111, 1234, 1289, 2378, 234, 3289, 123, 657, 467],
						borderColor: "#262261",
						color: "rgba(38, 35, 97, 0.1)",
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("leden", ["getInactiveMembers"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;
				const result = await this.getInactiveMembers();
				this.chartOptions.series[0].data =
					result.length > 0 ? result.map((item) => item.aantal) : _.fill(Array(11), 0);
				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 42px);
	left: calc(100% - 42px);
}
</style>
